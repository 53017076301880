<template>
  <div>
    <FiltersContainer>
      <el-select
        v-model="statusSearch"
        placeholder="選擇狀態"
        clearable
        @change="refresh"
      >
        <el-option
          v-for="item in ['wait', 'complete', 'cancel', 'fail']"
          :key="item"
          :label="statusTrans(item)"
          :value="item"
        />
      </el-select>
    </FiltersContainer>
    <PageTitle btn="新增" @btnClick="$router.push({name: 'PushCoupon'})" />
    <section v-loading="loading">
      <el-table :data="messageList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column
          prop="messages"
          label="票券"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.Coupon.name || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="類型"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ couponTypeConfig(scope.row.Coupon.type) || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="messages"
          label="搭配訊息"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ getData(scope.row, 'setting.message', '-') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="expectCount"
          label="預計發送人數"
          align="center"
        >
          <template slot-scope="scope">
            {{ getData(scope.row, 'expectCount', '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="resultCount" label="實際發送人數" align="center">
          <template slot-scope="scope">
            {{ scope.row.resultCount || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="expectSendAt"
          label="預計發送時間"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.expectSendAt, '-') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sendAt"
          label="實際發送時間"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.sendAt, '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.status)">
              {{
                statusTrans(scope.row.status)
              }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="備註" align="center">
          <template slot-scope="scope">
            {{ getData(errorCodeMsg, scope.row.errorCode, getData(scope.row, 'errorMsg', '-')) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="檢視"
              deleteBtn="取消發送"
              :deleteDisabled="scope.row.status !== 'wait'"
              @edit="findPushMessage(scope.row), (selectRow = scope.row)"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <el-drawer
        title="訊息"
        :visible.sync="showDetail"
        direction="rtl"
      >
        <div class="flex flex-col justify-between h-full">
          <el-form label-position="left" label-width="150px" :show-message="false" style="padding-left: 24px; padding-right: 24px;">
            <el-form-item label="票券" :show-message="false">
              {{ getData(selecMessage, 'Coupon.name', '-') }}
            </el-form-item>
            <el-form-item label="搭配訊息" :show-message="false">
              {{ getData(selecMessage, 'setting.message', '-') }}
            </el-form-item>
            <el-form-item label="預計發送人數">
              {{ getData(selecMessage, 'expectCount', '-') }}
            </el-form-item>
            <el-form-item label="實際發送人數">
              {{ getData(selecMessage, 'resultCount', '-') }}
            </el-form-item>
            <el-form-item label="預計發送時間">
              {{ dateFormat(getData(selecMessage, 'expectSendAt', ''), '-') }}
            </el-form-item>
            <el-form-item label="實際發送時間">
              {{ dateFormat(getData(selecMessage, 'sendAt', ''), '-') }}
            </el-form-item>
            <el-form-item label="狀態">
              <Tag :type="tagType(getData(selecMessage, 'status', ''))">
                {{ statusTrans(getData(selecMessage, 'status', '-')) }}
              </Tag>
            </el-form-item>
            <el-form-item label="備註">
              {{ getData(errorCodeMsg, selecMessage.errorCode, getData(selecMessage, 'errorMsg', '-')) }}
            </el-form-item>
            <el-form-item label="對象">
              <template v-if="!getMessageSendTarget().length">
                所有會員
              </template>
              <template v-if="getMessageSendTarget().length">
                <div v-for="(target, index) in getMessageSendTarget()" :key="index">
                  <div class="flex">
                    <div class="whitespace-nowrap">包含：</div>
                    <div v-if="!target.includes">-</div>
                    <div v-if="target.includes">
                      <template v-for="(name, n_index) in target.includes">
                        <span v-if="name" :key="`name-${name}`">{{ name }}</span>
                        <span v-if="!name" :key="`delete-${n_index}`" style="color: var(--gray-50)">已刪除的標籤</span>
                        <span v-if="n_index !== target.includes.length - 1" :key="n_index">、</span>
                      </template>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="whitespace-nowrap">排除：</div>
                    <div v-if="!target.excludes">-</div>
                    <div v-if="target.excludes">
                      <template v-for="(name, n_index) in target.excludes">
                        <span v-if="name" :key="`name-${name}`">{{ name }}</span>
                        <span v-if="!name" :key="`delete-${n_index}`" style="color: var(--gray-50)">已刪除的標籤</span>
                        <span v-if="n_index !== target.excludes.length - 1" :key="n_index">、</span>
                      </template>
                    </div>
                  </div>
                  <p v-if="index !== getMessageSendTarget().length - 1">or</p>
                </div>
              </template>
            </el-form-item>
          </el-form>
          <div class="flex justify-end" style="padding-left: 24px; padding-right: 24px;">
            <el-button :disabled="getData(selecMessage, 'status', '') !== 'wait'" plain @click="deleteDialog=true">取消發送</el-button>
            <el-button type="primary" @click="showDetail=false">返回</el-button>
          </div>
        </div>
      </el-drawer>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="messageCount"
        @pageChange="refresh"
      />
    </section>

    <WarningDialog
      v-if="deleteDialog"
      title="提示"
      content="取消發送後將無法復原，確定取消發送？"
      @close="deleteDialog = false"
      @confirm="cancelPushMessage(), (deleteDialog = false), (showDetail=false)"
    />
  </div>
</template>

<script>
import WarningDialog from '@/components/Dialog/WarningDialog'
import { mapGetters } from 'vuex'
import {
  GetPushMessage,
  GetPushMessageCount,
  CancelPushMessage,
  FindPushMessage,
} from '@/api/pushMessage'
import { pageStartIndex } from '@/utils/table'
import MixinFunc from '@/components/MixinFunc.vue'
import { get, map } from 'lodash'
import { errorCodeMsg, tagType as tagTypeConfig, status as statusConfig } from '@/config/pushMessage'

const action = 'coupon'

export default {
  name: 'PushMessageSetting',
  components: { WarningDialog },
  mixins: [MixinFunc],
  data: () => ({
    errorCodeMsg,
    tab: 'group',
    deleteDialog: false,
    statusSearch: '',
    loading: false,
    selectRow: null,
    selecMessage: {},
    showDetail: false,

    messageList: [],
    messageCount: 0,

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    getData (obj, key, defaultValue) {
      if (!obj) return defaultValue
      const res = get(obj, key, defaultValue)
      if (res === 0) return '0'
      return res || defaultValue
    },
    getMessageSendTarget () {
      const conditions = this.selecMessage.conditions

      const data = []

      if (!conditions) return data
      if (!conditions.length) return data

      conditions.forEach(con => {
        const excludes = map(con.excludes, i => i.name || '')
        const includes = map(con.includes, i => i.name || '')
        const list = {}
        if (excludes.length) list.excludes = excludes
        if (includes.length) list.includes = includes
        if (excludes.length || includes.length) data.push(list)
      })

      return data
    },
    statusTrans (status) {
      return get(statusConfig, `${status}.name`, '')
    },

    tagType (status) {
      return get(tagTypeConfig, status, '')
    },

    async refresh () {
      this.loading = true
      await this.getPushMessage()
      await this.getPushMessageCount()
      this.loading = false
    },

    async findPushMessage (row) {
      const [res, err] = await FindPushMessage({
        shopId: this.shop,
        id: row.id,
      })
      if (err) {
        this.$message.error(err.message)
        return
      }
      this.selecMessage = res
      this.showDetail = true
    },

    //= > 取得推播訊息
    async getPushMessage () {
      try {
        this.messageList = await GetPushMessage({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          status: this.statusSearch === '' ? undefined : this.statusSearch,
          action,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得推播訊息總數
    async getPushMessageCount () {
      try {
        this.messageCount = await GetPushMessageCount({
          shopId: this.shop,
          status: this.statusSearch === '' ? undefined : this.statusSearch,
          action,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async cancelPushMessage () {
      try {
        await CancelPushMessage({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.$message.success('刪除推播訊息成功 !')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    couponTypeConfig (val) {
      console.log(val)
      const couponTypeConfig = {
        coupon: '使用券',
        exchange: '兌換券',
      }
      return couponTypeConfig[val]
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
  @apply mb-0 ;
}

::v-deep .el-form-item__label {
  @apply text-gray-80 text-normal font-normal;
}
::v-deep .el-form-item__content {
  @apply text-gray-100 text-normal font-medium;
}

::v-deep .el-drawer.rtl{
  width: 600px !important;
}

::v-deep .el-drawer__header{
  @apply mb-[20px];
}
</style>
