export const errorCodeMsg = {
  CANCLED_BY_LINE_QUOTA_EXCEEDED: 'Line訊息剩餘額度不足',
}

export const status = {
  wait: { name: '已排程', value: 'wait' },
  complete: { name: '發送成功', value: 'complete' },
  cancel: { name: '已取消', value: 'cancel' },
  fail: { name: '發送失敗', value: 'fail' },
}

export const tagType = {
  wait: 'warning',
  complete: 'action',
  cancel: 'info',
  fail: 'danger',
}
