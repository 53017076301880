<template>
  <div class="push-message-setting">
    <PageTitle
      title="分群推播列表"
      btn="新增"
      hideBtn
    />
    <LineNotifyQuota v-if="!loading" style="margin-bottom: 20px" />
    <BasicTabs
      v-model="tab"
      style="margin-bottom: 20px"
      :tabs="tabs"
      @change="tabChange"
    />

    <MessageTable v-if="tab === 'message' " />
    <CouponTable v-if="tab === 'coupon' " />
  </div>
</template>

<script>
import MessageTable from './components/MessageTable.vue'
import CouponTable from './components/CouponTable.vue'
import BasicTabs from '@/components/Tabs/BasicTabs.vue'
import { mapGetters } from 'vuex'
import {
  GetPushMessage,
  GetPushMessageCount,
  CancelPushMessage,
} from '@/api/pushMessage'
import { pageStartIndex } from '@/utils/table'
import MixinFunc from '@/components/MixinFunc.vue'
import { checkUserFeature } from '@/store/modules/permission'
import LineNotifyQuota from '@/components/Progress/LineNotifyQuota.vue'

export default {
  name: 'PushMessageSetting',
  components: { BasicTabs, MessageTable, CouponTable, LineNotifyQuota },
  mixins: [MixinFunc],
  data: () => ({
    tab: 'message',
    tabs: [
      {
        label: '訊息設定',
        value: 'message',
      },

    ],
    statusSearch: '',
    loading: false,
    selectRow: null,

    messageList: [],
    messageCount: 0,

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    useCoupon () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.coupon.page')
    },
    tabQuery () {
      return this.$route.query.tab
    },
  },

  async mounted () {
    if (this.useCoupon) {
      this.tabs.push({
        label: '票券設定',
        value: 'coupon',
      })
    }
    if (this.tabQuery) {
      this.tab = this.tabQuery
      if (!this.useCoupon) this.tab = 'message'
    }
    await this.refresh()
  },

  methods: {
    tabChange (tab) {
      this.$router.push({
        name: 'PushMessageSetting',
        query: {
          tab,
        },
      })
    },
    statusTrans (status) {
      const dict = {
        wait: '未發送',
        complete: '已發送',
        cancel: '已取消',
        fail: '失 敗',
      }

      return dict[status]
    },

    tagType (status) {
      const dict = {
        wait: 'warning',
        complete: 'action',
        cancel: 'info',
        fail: 'danger',
      }

      return dict[status]
    },

    async refresh () {
      this.loading = true
      await this.getPushMessage()
      await this.getPushMessageCount()
      this.loading = false
    },

    //= > 取得推播訊息
    async getPushMessage () {
      try {
        this.messageList = await GetPushMessage({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          status: this.statusSearch === '' ? undefined : this.statusSearch,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得推播訊息總數
    async getPushMessageCount () {
      try {
        this.messageCount = await GetPushMessageCount({
          shopId: this.shop,
          status: this.statusSearch === '' ? undefined : this.statusSearch,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async cancelPushMessage () {
      try {
        await CancelPushMessage({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.$message.success('刪除推播訊息成功 !')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
  },
}
</script>
